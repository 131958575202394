import React, { useState } from "react";
import "./css/Organiser.css";
import ReactGA from "react-ga4";
import localpass from "../../Assets/localpass.png";
import megapass from "../../Assets/megapass.svg";

import us from "../../Assets/Us.svg";

import { FaCartShopping } from "react-icons/fa6";
import eventgemLogo from "../../Assets/eventgemLogo.svg";

import { MdKeyboardArrowDown } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  decreaseQuantity,
  increaseQuantity,
  initializeTicketTypes,
  updateQuantity,
  getTotalTickets,
  getTotalCost,
  updateTicketCart,
  getTaxes,
} from "../../redux/ticket";
import { useEffect } from "react";
import store from "../../redux/store";
import axios from "axios";
import { devApi } from "../../utils/constants";
import { toast } from "react-toastify";
import validator from "validator";
import _ from "lodash";
const regForEmail = RegExp(
  /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/
);
export default function DupageTickets({
  data,
  ticketOrder,
  getLoadFromTicket,
}) {
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.user.token);
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
  });
  const [signupdata, setSignupdata] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
  });
  const navigate = useNavigate();
  const taxes = useSelector(getTaxes);
  // const initialTicketTypes = data.data.tickets.map(ticket => ({
  //   type: ticket.description,
  //   price: parseInt(parseFloat(ticket.price)),
  //   quantity: 1
  // }));
  const ticketTypes = useSelector((state) => state.tickets.ticketTypes);
  const totalCost = useSelector(getTotalCost);
  const totalTickets = useSelector(getTotalTickets);
  const dispatch = useDispatch();
  const [checkoutSuccess, setCheckoutSuccess] = useState({
    mobileSuccess: false,
    emailSuccess: false,
    agreeSuccess: false,
  });
  const handleCheckboxChange = (e) => {
    setCheckoutSuccess({
      ...checkoutSuccess,
      agreeSuccess: e.target.checked,
    });
  };

  useEffect(() => {
    const loadInitialState = async () => {
      const storedState = localStorage.getItem("ticketState");
      if (
        storedState &&
        JSON.parse(storedState).ticketTypes[0].event_id ===
          data.data.event.id &&
        _.size(JSON.parse(storedState).ticketTypes) ===
          _.size(data.data.tickets)
      ) {
        const initialState = JSON.parse(storedState);
        store.dispatch(initializeTicketTypes(initialState.ticketTypes));
      } else {
        try {
          const initialTicketTypes = data.data.tickets.map((ticket) => ({
            id: ticket.id,
            type: ticket.title,
            full_price: parseInt(parseFloat(ticket.full_price)),
            isEarlyBird: ticket.early_bird,
            price: parseInt(parseFloat(ticket.price)),
            quantity: 0,
            event_id: data.data.event.id,
            description: ticket.description,
          }));
          store.dispatch(initializeTicketTypes(initialTicketTypes));
          localStorage.setItem(
            "ticketState",
            JSON.stringify({ ticketTypes: initialTicketTypes })
          );
        } catch (error) {
          console.error("Error fetching initial state from API:", error);
        }
      }
    };
    loadInitialState();
  }, []);

  const checkError = () => {
    const newErrors = { ...errors };
    let dataIsValid = true;
    if (!signupdata.first_name) {
      dataIsValid = false;
      newErrors.first_name = "First Name is required";
    } else if (validator.isAlpha(signupdata.first_name)) {
      newErrors.first_name = "";
    } else {
      newErrors.first_name = "Please enter a valid first name";
    }
    if (!signupdata.last_name) {
      dataIsValid = false;
      newErrors.last_name = "Last Name is required";
    } else if (validator.isAlpha(signupdata.last_name)) {
      newErrors.last_name = "";
    } else {
      newErrors.last_name = "Please enter a valid last name";
    }
    if (!signupdata.email) {
      dataIsValid = false;
      newErrors.email = "Email is required";
    } else if (!regForEmail.test(signupdata.email)) {
      dataIsValid = false;
      newErrors.email = "Invalid email";
    } else {
      newErrors.email = "";
    }
    if (signupdata.phone) {
      if (validator.isMobilePhone(signupdata.phone)) {
        newErrors.phone = "";
      }
    }

    if (!dataIsValid) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };
  const makePayment = async () => {
    if (!checkError()) {
      return;
    }
    setLoading(true);
    getLoadFromTicket(true);

    var counts = {};
    const ticketData = ticketTypes.map((ticket) => {
      var key = `ticket_${ticket.id}`;
      counts = { ...counts, [key]: ticket.quantity };
      return ticket.id;
    });
    const body = {
      ...counts,
      event_id: data.data.event.id,
      tickets: ticketData,
    };
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    };
    await axios
      .post(`${devApi}/api/e/postValidateTickets`, body, headers)
      .then((response) => {
        if (response.data.success) {
          ReactGA.gtag("event", "begin_checkout", {
            value: response.data.data.order_total,
            currency: "USD",
            coupon: "",
            items: response.data.data.tickets.map((item) => ({
              item_id: item.ticket.id,
              item_name: item.title,
              affiliation: item.title,
              coupon: "",
              discount: 0,
              index: 0,
              item_brand: "DuPage County Fair",
              price: item.price,
              quantity: item.qty,
            })),
          });
          store.dispatch(updateTicketCart(response.data.data));
          const config = {
            headers: {
              Authorization: "Bearer " + token,
              "Content-type": "application/json",
              Accept: "application/json",
              "Cache-Control": "no-cache",
              Pragma: "no-cache",
              Expires: "0",
            },
          };
          const body = {
            data: response.data.data,
            event_id: data.data.event.id,
            first_name: signupdata.first_name,
            last_name: signupdata.last_name,
            email: signupdata.email,
          };
          axios
            .post(`${devApi}/api/e/ticketPaymentCheckoutGuest`, body, config)
            .then(async (response) => {
              if (response.data.success) {
                window.location.replace(response.data.data.url);
              } else {
                setLoading(false);
                getLoadFromTicket(false);
                setErrors({ ...errors, api: response.data.message });
              }
            })
            .catch((error) => {
              getLoadFromTicket(false);
              setLoading(false);
              if (error.response.status === 450) {
                toast.error("Session Expired, Please log-in again");
                navigate("/login");
              }
            });
        } else {
          setErrors({ ...errors, api: response.data.message });
        }
      })
      .catch((error) => {
        getLoadFromTicket(false);
        setLoading(false);
        if (error.response.status === 450) {
          toast.error("Session Expired, Please log-in again");
          navigate("/login");
        }
      });
  };
  // const [ticketTypes, setTicketTypes] = useState(initialTicketTypes);
  const updateData = (e) => {
    let { name, value } = e.target;
    setSignupdata({
      ...signupdata,
      [e.target.name]: e.target.value[0] === " " ? "" : e.target.value,
    });
    setErrors({ ...errors, [e.target.name]: "" });
    // const isMatch = name === 'confirm_password' && signupdata.password === value;
    switch (name) {
      case "first_name":
        setErrors({
          ...errors,
          first_name: value.length === 0 ? "Name is required" : "",
        });
        break;
      case "email":
        setErrors({
          ...errors,
          email: value.length === 0 ? "Email is required" : "",
        });
        break;
      // case "confirm_password":setErrors({...errors,confirm_password: !isMatch ? "Password must not match the original password." : ""})
      // break;
      default:
        break;
    }
  };
  const handleDecrease = (index) => {
    ReactGA.gtag("event", "view_item", {
      currency: "USD",
      value: (ticketTypes[index].quantity - 1) * ticketTypes[index].price,
      items: [
        {
          item_id: `SKU_${ticketTypes[index].type}`,
          item_name: `${ticketTypes[index].type}`,
          affiliation: "",
          coupon: "",
          discount: 5,
          index: index,
          item_brand: "DuPage County Fair Ticket",
          location_id: "",
          price: ticketTypes[index].price,
          quantity: ticketTypes[index].quantity - 1,
        },
      ],
    });
    ReactGA.gtag("event", "remove_from_cart", {
      currency: "USD",
      value: (ticketTypes[index].quantity - 1) * ticketTypes[index].price,
      items: [
        {
          item_id: `SKU_${ticketTypes[index].type}`,
          item_name: `${ticketTypes[index].type}`,
          affiliation: "",
          coupon: "",
          discount: 5,
          index: index,
          item_brand: "DuPage County Fair Ticket",
          location_id: "",
          price: ticketTypes[index].price,
          quantity: ticketTypes[index].quantity - 1,
        },
      ],
    });
    dispatch(decreaseQuantity({ index }));
  };

  const handleIncrease = (index) => {
    ReactGA.gtag("event", "view_item", {
      currency: "USD",
      value: (ticketTypes[index].quantity + 1) * ticketTypes[index].price,
      items: [
        {
          item_id: `SKU_${ticketTypes[index].type}`,
          item_name: `${ticketTypes[index].type}`,
          affiliation: "",
          coupon: "",
          discount: 5,
          index: index,
          item_brand: "DuPage County Fair Ticket",
          location_id: "",
          price: ticketTypes[index].price,
          quantity: ticketTypes[index].quantity + 1,
        },
      ],
    });
    ReactGA.gtag("event", "add_to_cart", {
      currency: "USD",
      value: (ticketTypes[index].quantity + 1) * ticketTypes[index].price,
      items: [
        {
          item_id: `SKU_${ticketTypes[index].type}`,
          item_name: `${ticketTypes[index].type}`,
          affiliation: "",
          coupon: "",
          discount: 5,
          index: index,
          item_brand: "DuPage County Fair Ticket",
          location_id: "",
          price: ticketTypes[index].price,
          quantity: ticketTypes[index].quantity + 1,
        },
      ],
    });
    dispatch(increaseQuantity({ index }));
  };

  const handleQuantityChange = (event, index) => {
    const newQuantity = parseInt(event.target.value) || 0;
    dispatch(updateQuantity({ index, quantity: newQuantity }));
  };

  if (loading) {
    return (
      <div className="d-flex flex-column justify-content-center text-center align-items-center ndf">
        <span className="redirect-text ">
          You are being redirected to the secured payment gateway
        </span>
        <span>Do not click the "Back" button!</span>
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="container ">
        <div>
          <hr className="hr-dupage"></hr>
        </div>
        <div className=" d-flex flex-column justify-content-center align-items-center my-4">
          <div
            className="col-lg-10 col-md-12 col-sm-12 col-12"
            ref={ticketOrder}
          >
            {ticketTypes.map((ticketType, index) => {
              return (
                <div className="event-order-box mt-3">
                  <div
                    className="d-flex flex-row align-items-center justify-content-between my-4"
                    key={index}
                  >
                    <div className="d-flex flex-row justify-items-center mt-0.5 ticket-type-box">
                      {ticketType.type !== "Mega Pass" ? (
                        <img className="localpass" src={localpass} alt="" />
                      ) : (
                        <img className="localpass" src={megapass} alt="" />
                      )}
                      <div className="d-flex flex-column justify-items-center mt-0.5 ">
                        <div className="d-flex flex-row justify-items-center mt-0.5 ">
                          {ticketType.type !== "Mega Pass" ? (
                            <>
                              {/*   <small className={"admission"}>
                                Admission :{" "}
                              </small>*/}
                              <small className={"normal-dupage-pass "}>
                                {ticketType.type}
                              </small>
                            </>
                          ) : (
                            <div className="d-flex flex-column">
                              <small className={"mega-pass "}>
                                {ticketType.type}
                              </small>
                            </div>
                          )}
                        </div>
                        <h4 className="description-text mt-1">
                          <strong>{ticketType.description}</strong>
                        </h4>
                      </div>
                    </div>
                    <div className="d-flex flex-sm-row flex-column justify-content-between align-items-center price-quantity">
                      {ticketType.price !== 0 ? (
                        <>
                          <h6 className="paycharge me-sm-2 ticket-price">
                            Price : $ {ticketType.price}
                          </h6>
                        </>
                      ) : (
                        <div className="free me-sm-2">Free</div>
                      )}
                      <div className="quantity justify-content-center text-center align-items-center ms-sm-2 mt-1 mt-lg-0">
                        <button
                          className="minus"
                          onClick={() => handleDecrease(index)}
                          disabled={ticketType.quantity <= 0}
                        >
                          -
                        </button>
                        <input
                          className="input-box"
                          type="number"
                          value={ticketType.quantity}
                          onChange={(event) =>
                            handleQuantityChange(event, index)
                          }
                        />
                        <button
                          className="plus"
                          onClick={() => handleIncrease(index)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr className="hr-ticket" />
                  <div className="d-flex flex-column justify-content-start align-items-start">
                    <h4 className="description-text">*No Refunds</h4>
                    {ticketType.type == "Mega Pass" ? (
                      <h4 className="description-text mt-1">
                        *Each Mega Pass includes Admission and Unlimited
                        Carnival Rides <strong>FOR ONE DAY</strong>
                      </h4>
                    ) : (
                      <h4 className="description-text mt-1">
                        *Each Ticket is valid for{" "}
                        <strong>ONLY ONE PERSON FOR ONE DAY</strong>
                      </h4>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className=" col-lg-10 col-md-12 col-sm-12 col-12 d-flex flex-sm-row flex-column justify-content-between mt-4 ">
            <div className=" col-lg-5 d-flex flex-column  cart">
              <h4 className="cart-heading">Your Cart</h4>

              <div className="d-flex flex-row justify-content-between">
                <h4 className="dupage-totals">Order Total</h4>

                <h4 className="dupage-totals">$ {totalCost.toFixed(2)}</h4>
              </div>

              {totalTickets > 0 && totalCost !== 0 ? (
                <div className="d-flex flex-row justify-content-between">
                  <h4 className="processing-fees">Processing Fee</h4>
                  <h4 className="processing-fees">
                    $ {taxes.processingFee.toFixed(2)}
                  </h4>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center text-center">
                  <FaCartShopping
                    style={{
                      width: "32px",
                      height: "32px",
                      color: "#024DDF",
                    }}
                  />
                  <p className="under-ticket1 mt-4">
                    Your cart is currently empty!
                  </p>
                </div>
              )}
              {totalTickets > 0 && totalCost !== 0 ? (
                <>
                  {" "}
                  <hr className="hr-dupage"></hr>
                  <div className="d-flex flex-row justify-content-between">
                    <h4 className="dupage-totals">Total</h4>
                    <h4 className="dupage-totals">
                      $ {(totalCost + taxes.processingFee).toFixed(2)}
                    </h4>
                  </div>
                </>
              ) : null}
              <div className="d-flex flex-row justify-content-center text-center mt-4">
                <h4 className="processing-fees">
                  You will be billed as
                  <img src={eventgemLogo} alt="" className="img-fluid ms-1" />
                  .com
                </h4>
              </div>
            </div>
            <div className="col-lg-6  mt-4 mt-lg-0 event-order-box">
              <h4 className="cart-heading">Contact Info</h4>
              <div className="d-flex  mb-2 mt-2">
                <div
                  className="d-inline flex-column mb-2 "
                  style={{ width: "50%" }}
                >
                  <label htmlFor="" className="signup-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="signup-input-name"
                    placeholder="Enter First Name"
                    name="first_name"
                    onChange={updateData}
                    value={signupdata.first_name}
                  />
                  <small className="text-danger ml-2">
                    {errors.first_name}
                  </small>
                </div>
                <div
                  className="d-inline flex-column mb-2 ms-2"
                  style={{ width: "50%" }}
                >
                  <label for="formLastName" className="signup-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="formLastName"
                    className="signup-input-name"
                    placeholder="Enter Last Name"
                    name="last_name"
                    onChange={updateData}
                    value={signupdata.last_name}
                  />
                  <small className="text-danger ml-2">{errors.last_name}</small>
                </div>
              </div>

              <div className="mb-2">
                <label htmlFor="" className="signup-label">
                  Mobile Number
                </label>
                <div className="d-flex">
                  {" "}
                  <div
                    className="form-input4 text-center align-items-center"
                    style={{ padding: "12px 12px" }}
                  >
                    <img
                      src={us}
                      alt=""
                      className="img-fluid flag-img me-1"
                      style={{
                        width: "24px",
                        height: "23px",
                        marginBottom: 0,
                      }}
                    />{" "}
                    <small className="me-1 align-items-center">+1</small>
                  </div>
                  <input
                    id="phone"
                    name="phone"
                    type="text"
                    className="signup-input ms-1"
                    onChange={updateData}
                    placeholder="Enter your mobile number"
                    value={signupdata.phone}
                  />
                </div>
                <small className="text-danger ml-2">{errors.phone}</small>
              </div>

              <div className="mb-2">
                <label htmlFor="" className="signup-label">
                  Email
                </label>
                <input
                  type="text"
                  className="signup-input"
                  placeholder="Enter Email"
                  name="email"
                  onChange={updateData}
                  value={signupdata.email}
                />
                <small className="text-danger ml-2">{errors.email}</small>
              </div>
            </div>
          </div>
          <div className="col-lg-10 col-md-12 col-sm-12 col-12 mt-4">
            <div
              className="d-flex terms-condition justify-content-between "
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <h6 className="mx-3 mt-4">Terms and Conditions</h6>
              <MdKeyboardArrowDown
                className="mx-3 mt-4"
                style={{ width: "24px", height: "24px", color: "#024DDF" }}
              />
            </div>

            <div className="collapse terms-body" id="collapseExample">
              <div className="terms-body terms-text card-body mx-2">
                <ul>
                  <li>
                    Cancellation Policy: Tickets are NON-TRANSFERABLE and
                    NON-REFUNDABLE unless the event is canceled or rescheduled.
                  </li>
                  <li>
                    Refund Policy: Refunds are offered only if an event is
                    canceled, rescheduled, or moved. Refunds go back to the
                    original payment mode.
                  </li>
                  <li>
                    In case of event cancellation by the organizer, platform
                    fees and processing fees are NON-REFUNDABLE.
                  </li>
                  <li>
                    If you have any concerns about event cancellations or
                    refunds, please contact the event organizer directly. Only
                    genuine mobile tickets with QR codes will be accepted for
                    entry.
                  </li>
                  <li>
                    Arrive at the event at least 30 minutes prior to avoid rush
                    at the check-in counter.
                  </li>
                  <li>
                    A standard admission ticket provides a single entry to the
                    venue. Once you leave, you cannot re-enter using the same
                    ticket.
                  </li>
                  <li>
                    The tickets purchased are subject to Eventgem’s Terms and
                    Conditions, Privacy Policy, and Cookie Policy.
                  </li>
                  <li>
                    Privacy Policy: Users must review and agree to the privacy
                    policy, which details data handling and security.
                  </li>
                  <li>
                    Prohibited Activities: Users are prohibited from engaging in
                    illegal activities, violating intellectual property rights,
                    or using the service for unauthorized commercial purposes.
                  </li>
                  <li>
                    Disclaimer: Services are provided "as is" without any
                    warranties. Eventgem is not responsible for any third-party
                    links or services.
                  </li>
                </ul>
              </div>
            </div>
            <div className="d-flex  terms-accept align-items-center ">
              <input
                className="form-check-input checkbox-inline ms-3 my-3"
                type="checkbox"
                name="inlineRadioOptions"
                id="inlineRadio1"
                value="option1"
                onChange={handleCheckboxChange}
              />
              <label
                className="form-check-label acceptText ms-2 my-3"
                for="inlineRadio1"
              >
                By clicking here, I state that I have read and understood the
                "Terms and Conditions".
              </label>
            </div>
            {/*  {checkoutSuccess.agreeSuccess && totalTickets !== 0 ? (
              <div className="d-flex flex-column justify-content-center align-items-center text-center mx-3 mt-4">
                <h5 className="confirm-text">
                  Are you sure you want to proceed to checkout?
                </h5>
                <p className="acceptText">
                  You are redirecting to the payment screen. Please modify your
                  tickets now, if any.
                </p>
              </div>
            ) : (
              ""
            )}*/}
          </div>
          {/* <div className="d-flex justify-content-center align-items-center">
            <button
              className={
                totalTickets == 0 ||
                totalCost == 0 ||
                !checkoutSuccess.agreeSuccess ||
                !signupdata.email ||
                !signupdata.first_name ||
                !signupdata.last_name
                  ? "proceedbtn-dis "
                  : "proceedbtn"
              }
              onClick={() => {
                makePayment();
              }}
              disabled={
                totalTickets == 0 ||
                totalCost == 0 ||
                !checkoutSuccess.agreeSuccess ||
                !signupdata.email ||
                !signupdata.first_name ||
                !signupdata.last_name
              }
            >
              {" "}
              Proceed
            </button>
          </div>*/}
        </div>
      </div>
    </>
  );
}

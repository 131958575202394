import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import NavrangGarbaRaas from "../../Assets/navrangRassGarba.png";
import PriyankaPoster1 from "../../Assets/priyankaPoster1.png";
import NavrangNavratri from "../../Assets/navrangNavratri.png";
import dupageHomeSlide from "../../Assets/dupageHomeSlide.png";

import Eticketing from "../../Assets/Mention.svg";
import SocialMediaIntegration from "../../Assets/SocialStrategy.svg";
import DoorManagement from "../../Assets/DataReport.svg";
import AdvancedAnalytics from "../../Assets/NoFaceMask.svg";

import { RxDividerVertical } from "react-icons/rx";
import { MdOutlineLocationOn, MdCalendarToday } from "react-icons/md";

import "../NewHome/css/NewHome.css";
import { useRef } from "react";
import { useEffect } from "react";

import axios from "axios";
import moment from "moment";
import validator from "validator";
import { toast } from "react-toastify";
import { devApi } from "../../utils/constants";
export default function NewHome() {
  const [homepageData, setHomepageData] = useState();
  const [loading, setLoading] = useState(false);
  const [emailEnquiry, setEmailEnquiry] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    emailSuccess: "",
  });
  const navigate = useNavigate();

  const handleSubmit = () => {
    setLoading(true);
    if (emailEnquiry.email) {
      if (validator.isEmail(emailEnquiry.email)) {
        setTimeout(async () => {
          const body = {
            email: emailEnquiry.email,
          };
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          };
          await axios
            .post(`${devApi}/api/eventgem/organiserQuery`, body, headers)
            .then((res) => {
              if (res.data.success) {
                setLoading(false);
                setErrors({ ...errors, emailSuccess: res.data.message });
              } else {
                setLoading(false);
                if (res.data.error.email[0]) {
                  setErrors({ ...errors, email: res.data.error.email[0] });
                }
              }
            })
            .catch((error) => {
              if (error.response.status === 450) {
                toast.error("Session Expired, Please log-in again");
                navigate("/login");
              }
            });
        }, 1000);
      } else {
        setLoading(false);
        setErrors({ ...errors, email: "Please enter a valid email id" });
      }
    } else {
      setLoading(false);
      setErrors({ ...errors, email: "Please provide your email" });
    }
  };

  const updateData = (e) => {
    setEmailEnquiry({
      ...emailEnquiry,
      [e.target.name]: e.target.value[0] === " " ? "" : e.target.value,
    });
    setErrors({ ...errors, [e.target.name]: "", emailSuccess: "" });
  };
  const sliderRef = useRef(null);
  const upcomingeventref = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      slider.innerSlider.slickGoTo(1);
    }
  }, []);
  useEffect(() => {
    const slider = upcomingeventref.current;
    if (slider) {
      slider.innerSlider.slickGoTo(1);
    }
  }, []);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        await axios
          .get(`${devApi}/api/eventgem`)
          .then((response) => {
            if (response.data.success) {
              setHomepageData(response.data.data);
            }
          })
          .catch((error) => {
            if (error.response.status === 450) {
              toast.error("Session Expired, Please log-in again");
              navigate("/login");
            }
          });
      } catch (error) {
        console.error("Error fetching event details:", error);
      }
    };

    fetchEventDetails();
  }, []);

  const setSlideVisibility = () => {
    const slider = sliderRef.current.innerSlider;
    if (slider) {
      const dots = slider.dots;
      if (dots && dots[0] && dots[0].children) {
        Array.from(dots[0].children).forEach((dot, index) => {
          dot.style.opacity = index === slider.currentSlide ? 1 : 0.5;
        });
      }
    }
  };
  const setSlideVisibilityupcoming = () => {
    const slider = upcomingeventref.current.innerSlider;
    if (slider) {
      const dots = slider.dots;
      if (dots && dots[0] && dots[0].children) {
        Array.from(dots[0].children).forEach((dot, index) => {
          dot.style.opacity = index === slider.currentSlide ? 1 : 0.5;
        });
      }
    }
  };
  const setting = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 10000,
    arrows: true,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: "110px",
    afterChange: setSlideVisibility,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          autoplay: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          autoplay: true,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          autoplay: true,
        },
      },
    ],
  };

  const banners = [
    {
      image: NavrangGarbaRaas,
      title:
        "Navrang Raas Garba - Biggest Indoor Garba with Priyanka Kher like never before.",
      location: "Pearl Banquets, Chicago",
      date: "April 07th, Sun",
      time: "07:30 PM (01:30 hrs)",
      tag: "Music",
      onClicklink: "",
    },
    {
      image: PriyankaPoster1,
      title: "Garba Night - Non stop Navratri celebration with Priyanka Kher",
      location: "Pearl Banquets, Chicago",
      date: "April 07th, Sun",
      time: "07:30 PM (01:30 hrs)",
      tag: "Music",
      onClicklink: "/event/23",
    },
    {
      image: NavrangNavratri,
      title:
        "Navrang Navratri - Live concert with Priyanka Kher in USA, Chicago",
      location: "Pearl Banquets, Chicago",
      date: "April 07th, Sun",
      time: "07:30 PM (01:30 hrs)",
      tag: "Music",
      onClicklink: "",
    },
  ];

  const bannerDiv = homepageData?.eventsdata.map((banner) => {
    return (
      <div className="col-sm-12 col-lg-4 position-relative my-2">
        <Link
          to={
            banner.event_id == 29
              ? `/dupagefairevent`
              : `/event/${banner.event_id}`
          }
        >
          <img
            src={banner.event_image_fullpath}
            alt=""
            className="banner-images"
          />
        </Link>
        <Link
          className="text-decoration-none text-black"
          to={
            banner.event_id == 29
              ? `/dupagefairevent/`
              : `/event/${banner.event_id}`
          }
        >
          <div className="fs-6 fw-semibold mt-2 ms-2">{banner.title}</div>
        </Link>
        {banner.event_id !== 29 ? (
          <div className="banner-location d-flex flex-row ms-2">
            <MdCalendarToday
              style={{ width: "16px", height: "16px", color: "#024DDF" }}
            />
            <h5 className="banner-location ms-2">{banner.startday} </h5>
            <RxDividerVertical style={{ color: "#121212" }} />{" "}
            <h5 className="banner-location">
              {moment(banner.start_date).format("hh:mm A")}
            </h5>
          </div>
        ) : (
          <div className="banner-location d-flex flex-row ms-2">
            <MdCalendarToday
              style={{ width: "17px", height: "17px", color: "#024DDF" }}
            />{" "}
            <h5 className="banner-location ms-2">{banner.startEndDay} </h5>
            {/*<GoDotFill
              style={{ width: "8px", height: "8px", color: "#121212" }}
            />{" "}
        {moment(banner.start_date).format("hh:mm A")}*/}
          </div>
        )}
        <div className="banner-location d-flex flex-row ms-1">
          <MdOutlineLocationOn
            style={{ width: "24px", height: "24px", color: "#024DDF" }}
          />{" "}
          <h5 className="banner-location ms-1" style={{ maxWidth: "60%" }}>
            {banner.venue_name}, {banner.location_address_line_1},{" "}
            {banner.location_address_line_2}, {banner.location_post_code}
          </h5>
        </div>
      </div>
    );
  });

  const whyEventFull = [
    {
      image: Eticketing,
      title: "E- Ticketing",
      hoverText: `
        <p>Our e-ticketing solution lets your customers enjoy</p>
        <ul>
          <li>Easy ticket purchases.</li>
          <li>Immediate QR code delivery.</li>
          <li>Effortless check-in experience</li>
        </ul>
      `,
    },
    {
      image: SocialMediaIntegration,
      title: "Social Media Integration",
      hoverText: `
        <ul>
          <li>Track social media engagement and conversions.</li>
          <li>Measure the impact of social media campaigns on ticket sales</li>
          <li>Optimize your social strategy for better results.</li>
        </ul>
      `,
    },
    {
      image: DoorManagement,
      title: "Door Management",
      hoverText: `
        <ul>
          <li>Reduces wait times and frustration at entry points.</li>
          <li>Improve crowd flow and event experience.</li>
          <li>Enhance overall event security and efficiency.</li>
        </ul>`,
    },
    {
      image: AdvancedAnalytics,
      title: "Advanced Analytics",
      hoverText: `
        <ul>
          <li>Unlock data-driven insights.</li>
          <li>Streamline and optimize your marketing efforts.</li>
          <li>Tailor your events to meet audience needs and boost ticket sales.</li>
        </ul>`,
    },
  ];

  const whyEventFullDiv = whyEventFull.map((eventFull) => {
    return (
      <div className="col-lg-3 col-sm-12 my-2">
        <div className="thumbnail">
          <p>{eventFull.title}</p>
          <div className="fade-out-div">
            <img
              src={eventFull.image}
              alt={eventFull.title}
              className="img-fluid image"
            />
            <div
              className="hover-text"
              dangerouslySetInnerHTML={{ __html: eventFull.hoverText }}
            ></div>
          </div>
        </div>
        {/*<div className="overlay">
          <div className="thumbnail">
            {" "}
            <p>{eventFull.title}</p>
          </div>
          <div
            className="text"
            dangerouslySetInnerHTML={{ __html: eventFull.hoverText }}
          />
        </div>*/}
      </div>
    );
  });

  // const sliderImgDiv = homepageData?.banners.map((img) => {
  //   return (
  //     <div className="slick-fav">
  //       <img className="img-fluid h-75" alt="" src={img.banner_img_fullpath} />
  //     </div>
  //   );
  // });
  if (!homepageData) {
    return (
      <div className="d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="col-12 banner-text">
        Never miss your favorite events again. Sign up for updates and get the
        inside scoop.
      </div>
      {/*<div className="my-2 text-center mx-4">
        <h5>{homepageData?.heading.title}</h5>
  </div>*/}
      <div className="container">
        <div className="d-flex flex-column align-items-start">
          <span className="badge rounded-pill bg-primary ms-2  mt-4 badges justify-content-center align-items-center">
            <h6>Upcoming Events</h6>
          </span>
          <div className="row my-4 justify-content-start">{bannerDiv}</div>
        </div>
        {/* <div className="d-flex flex-column align-items-start">
          <span className="badge rounded-pill bg-secondary ms-2  mt-4 badges justify-content-center align-items-center">
            <h6>Past Events</h6>
          </span>
          <div className="d-flex flex-row my-4 justify-content-start">
            <div className="col-sm-12 col-lg-6 position-relative my-2">
              <Link>
                <img
                  src={dupageHomeSlide}
                  alt=""
                  className="img-fluid banner-images"
                />
              </Link>
              <Link className="text-decoration-none text-black">
                <div className="fs-6 fw-semibold mt-2 ms-2">
                  DuPage County Fair
                </div>
              </Link>
              <div className="banner-location d-flex flex-row ms-2">
                <MdCalendarToday
                  style={{ width: "17px", height: "17px", color: "#024DDF" }}
                />{" "}
                <h5 className="banner-location ms-2">25th-28th July, 2024 </h5>
              </div>

              <div className="banner-location d-flex flex-row ms-1">
                <MdOutlineLocationOn
                  style={{ width: "24px", height: "24px", color: "#024DDF" }}
                />{" "}
                <h5
                  className="banner-location ms-1"
                  style={{ maxWidth: "60%" }}
                >
                  DuPage County Fairgrounds 2015 Manchester Road, Wheaton IL,
                  60187
                </h5>
              </div>
            </div>
          </div>
        </div>*/}
      </div>

      <div className="grey-bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="event-gem-title">
                <span>Eventgem</span> is not just about events.
                <br />
                It's the experience that makes the <span>difference</span>.
              </div>
              <div className="event-gem-subtitle"></div>
            </div>
          </div>

          <div className=" d-flex row mt-5">{whyEventFullDiv}</div>
        </div>
      </div>

      <div className="sliderCont">
        {/*<Slider ref={sliderRef} {...setting}>
          {sliderImgDiv}
</Slider>*/}

        <div className="container mt-4 text-center justify-content-center align-items-center fw-semibold">
          <p>Planning an event soon? Let’s talk and make it a success.</p>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-4 col-sm-12 col-md-6 align-items-center">
              <div className="input-group mb-3 justify-content-center align-items-center">
                <input
                  type="text"
                  name="email"
                  className="form-input1"
                  placeholder="Please provide your email"
                  aria-label="Email"
                  aria-describedby="basic-addon1"
                  onChange={updateData}
                  value={emailEnquiry.email}
                />
              </div>
              {errors.email && (
                <p className="text-danger ml-2 text-center">{errors.email}</p>
              )}
              {errors.emailSuccess && (
                <p className="text-primary ml-2 text-center">
                  {errors.emailSuccess}
                </p>
              )}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-12 col-md-6 align-items-center">
              {loading === true ? (
                <div className="d-flex justify-content-center align-items-center">
                  <button className="btn btn-primary" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                </div>
              ) : (
                <button className="btn btn-primary" onClick={handleSubmit}>
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

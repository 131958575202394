import React, { useEffect, useRef, useState } from "react";
import Banner from "./Banner";
import Organiser from "./Organiser";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { initializeTicketTypes, setEventDetails } from "../../redux/ticket";
import { updateEventId } from "../../redux/user";
import { devApi } from "../../utils/constants";
import axios from "axios";
import store from "../../redux/store";
import _ from "lodash";

export default function HomePage() {
  const [eventData, setEventData] = useState(null);
  const { inputValue } = useParams();
  const ticketSummaryRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateEventId(inputValue));
    const fetchEventDetails = async () => {
      try {
        await axios
          .get(`${devApi}/api/e/fetchEventDetails?event_id=${inputValue}`)
          .then((response) => {
            setEventData(response.data);
            dispatch(setEventDetails(response.data));
            loadInitialState(response.data);
          });
      } catch (error) {
        console.error("Error fetching event details:", error);
      }
    };
    const loadInitialState = async (data) => {
      const storedState = localStorage.getItem("ticketState");
      if (
        storedState &&
        JSON.parse(storedState).ticketTypes[0]?.event_id ===
          data.data.event.event_id &&
        _.size(JSON.parse(storedState).ticketTypes) ===
          _.size(data.data.tickets)
      ) {
        const initialState = JSON.parse(storedState);
        store.dispatch(initializeTicketTypes(initialState.ticketTypes));
      } else {
        try {
          const initialTicketTypes = data.data.tickets.map((ticket) => ({
            id: ticket.id,
            type: ticket.title,
            full_price: parseInt(parseFloat(ticket.full_price)),
            isEarlyBird: ticket.early_bird,
            price: parseInt(parseFloat(ticket.price)),
            quantity: 0,
            event_id: data.data.event.event_id,
            description: ticket.description,
            isPaused: ticket.is_paused,
          }));
          store.dispatch(initializeTicketTypes(initialTicketTypes));
          localStorage.setItem(
            "ticketState",
            JSON.stringify({ ticketTypes: initialTicketTypes })
          );
        } catch (error) {
          console.error("Error fetching initial state from API:", error);
        }
      }
    };

    fetchEventDetails();
  }, []);
  if (!eventData) {
    return (
      <div className="d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
  return (
    <>
      {eventData.data ? (
        <>
          <Banner ticketOrder={ticketSummaryRef} data={eventData} />
        </>
      ) : (
        <div className="container ">
          <div
            className="row d-flex align-items-center justify-content-center"
            style={{ height: "100vh" }}
          >
            <div className="col-lg-6">
              <h1 className="text-center">No data Found</h1>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
